.App {
  text-align: center;
  background-image: url('./assets/main-bg.jpg');
}

.headerDiv {
  text-align: left;
  padding-left: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.btn-navigate {
  border: none;
  background: white;
  height: 50px;
  font-weight: 600;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  padding: 0px 10px;
  border-right: 1px solid #dddddd;
}

.btn-navigate:nth-last-child(1) {
  border: none;
}

.btn-navigate:hover {
  background: #002240;
  color: white;
}

.btn-daftar {
  margin-left: auto;
  margin-right: 10px;
  background: #002240;
  border: none;
  color: white;
  font-weight: bolder;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-daftar:hover {
  background: #013562;
}

.banner {
  background-image: url('./assets/group-photo.jpg');
  margin: auto;
  background-size: cover;
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
}

.other-info-cont {
  display: flex;
  justify-content: center;
}

.contentDiv p, li {
  color: white;
}

.info-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #152443;
  padding: 10px;
  padding-right: 15px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  margin: 5px 10px;
}

li {
  text-align: left;
  font-size: 18px;
  margin-bottom: 10px;
}

.pattern {
  position: relative;
  height: calc( 100% + 100px );
  background-color: #3bb78f;
  background-image: linear-gradient(315deg,#3bb78f 0%, #0bab64 74%);
}

.pattern:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 280px;
  background: url('https://media.geeksforgeeks.org/wp-content/uploads/20200326181026/wave3.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

h3 {
  font-family: 'cursive';
  font-size: 25px;
  color: white;
}

footer {
  margin-top: 20px;
  padding: 50px 0;
  background-size: cover;
  background-image: url('./assets/footer.jpg');
}

.footer-content {
  display: flex;
  justify-content: center;
  background: #ffffffbf;
}

.footer-content .content-card {
  width: 250px;
  color: #152443;
  padding: 10px;
  border-radius: 8px;
  padding-bottom: 20px;
  margin: 10px 50px;
}

a {
  color: black;
}

.responsive-display {
  display: flex;
  justify-content: center;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customer svg {
  width: 20px;
  height: 20px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
}

@media screen and ( max-width: 640px ){
  .videoDiv {
    max-width: 80vw;
    display: flex;
    margin: auto;
  }
  .banner {
    height: 20vh;
  }
  .headerDiv {
    padding-left: 2px;
  }
  .btn-daftar {
    font-size: 12px;
    width: 100px;
  }
  .hidden {
    display: none;
  }

  #customers {
    font-size: 12px;
  }
  .btn-navigate {
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    border-right: none;
    border-bottom: 1px solid #dddddd;
  }
}

@media screen and ( max-width: 800px ){
  .responsive-display,
  .other-info-cont,
  .footer-content{
    display: block;
  }
  .footer-content .content-card{
    margin: auto;
  }

  .responsive-display img {
    width: 70% !important;
  }

  .pattern:before {
    height: 180px;
  }

  .responsive-display p {
    text-align: left !important;
  }
}